import React from 'react';
import PropTypes from 'prop-types';
import { Card, FONT_TYPES, Text, COLORS, Image, EmptyState, Checkbox, Icon } from '@vezeeta/web-components';
import { Flex } from 'grid-styled';
import styled from 'styled-components';
import { space, borderColor } from 'styled-system';
import IconsStore from '../../views/IconsStore';
const ItemCard = styled(Card)`
  border-style: solid;
  border-width: 1px;
  ${borderColor};
`;

const ItemsScrollContainer = styled.div`
  overflow-y: scroll;
  max-height: 300px;
  ${space};
`;

const VerticalSeperator = styled.div`
  border-right: 2px solid #e0e0e0;
  height: 24px;
`;

const ItemsList = props => {
  const { list, setSelected, selectedItemKey, emptyStateText, countries, languages } = props;
  const content =
    list.length > 0 ? (
      list.map(item => {
        const isSelected =
          (selectedItemKey === item.BundleKey || selectedItemKey === item.BannerKey) &&
          selectedItemKey !== undefined;
        let packageData = null;
        if (item.BundleKey) {
          packageData = (item.Payment.SubscriptionPeriod && (
            <React.Fragment>
              <VerticalSeperator />
              <Text color={COLORS.TEXT} type={FONT_TYPES.TITLE} mx={4}>
                {item.Payment.SubscriptionPeriod}
              </Text>
            </React.Fragment>
          ),
          item.Payment.Price && (
            <React.Fragment>
              <VerticalSeperator />
              <Text color={COLORS.TEXT} type={FONT_TYPES.TITLE} mx={4}>
                {item.Payment.Price}
              </Text>
            </React.Fragment>
          ));
        } else if (item.BannerKey && item.Culture) {
          const parts = item.Culture.split(/[- : /]/);
          const LanguageId = parts[0] === 'en' ? 1 : 2;
          // eslint-disable-next-line prefer-destructuring
          const CountryIso = parts[1];
          packageData = (
            <React.Fragment>
              {/* <Checkbox readOnly value={!item.IsDeleted} isChecked={!item.IsDeleted} onClick={(e) => {
                e.stop()
                return false
                }
                 }></Checkbox> */}
                {
                  !item.IsDeleted ?
                  <>
                    <VerticalSeperator /> 
                    <Text mx={2}>
                      <Icon  width={17} color={COLORS.PRIMARY_BLUE} icon={IconsStore.getIcon('success')} />
                    </Text> 
                  </>  
                 : ''
                }
              <VerticalSeperator />
              <Text color={COLORS.TEXT} type={FONT_TYPES.TITLE} mx={4}>
                {countries.find(country => country.ISOCode === CountryIso).NameEnglish}
              </Text>
              <VerticalSeperator />
              <Text color={COLORS.TEXT} type={FONT_TYPES.TITLE} mx={4}>
                {languages.find(language => language.Id === LanguageId).Name}
              </Text>
            </React.Fragment>
          );
        }
        return (
          <ItemCard
            px={4}
            py={4}
            mb={4}
            onClick={() => {
              setSelected(item.BundleKey || item.BannerKey);
            }}
            borderColor={isSelected ? COLORS.LINK : COLORS.DISABLED}
            key={item.BundleKey || item.BannerKey}
          >
            <Flex width={1} justifyContent="space-between">
              <Flex flexDirection="row" alignItems="center">
                <Text color={COLORS.PRIMARY_BLUE} type={FONT_TYPES.TITLE} mr={4}>
                  {item.BundleKey ? item.NameEn : item.DisplayOrder}
                </Text>
                {packageData}
              </Flex>
              {item.Url && <Image src={item.Url} radius={50} objectFit="fill" borderRadius="0" />}
            </Flex>
          </ItemCard>
        );
      })
    ) : (
      <Flex width={1} justifyContent="center" alignItems="center">
        <EmptyState
          text={emptyStateText}
          image={`${process.env.REACT_APP_CDN_URL}/assets/no_data.svg`}
          imageWidth={200}
        />
      </Flex>
    );

  return (
    <ItemsScrollContainer px={4} py={2}>
      {content}
    </ItemsScrollContainer>
  );
};

export default ItemsList;

ItemsList.propTypes = {
  list: PropTypes.array, // eslint-disable-line
  selectedItemKey: PropTypes.string,
  setSelected: PropTypes.func,
  emptyStateText: PropTypes.string,
  languages: PropTypes.array, // eslint-disable-line
  countries: PropTypes.array // eslint-disable-line
};

ItemsList.defaultProps = {
  list: [],
  selectedItemKey: '',
  setSelected: () => {},
  emptyStateText: 'No Information Available',
  countries: [],
  languages: []
};
