import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Separator,
  // Search,
  NoAnimationFlex,
  Rect,
  ShimmerEffect,
  COLORS,
  Button
} from '@vezeeta/web-components';
import { Box, Flex } from 'grid-styled';
import styled from 'styled-components';

import ItemsList from './ItemsList';

const AddButton = styled(Button)`
  width: 100%;
  max-width: -webkit-fill-available;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

class ItemsListContainer extends Component {
  static propTypes = {
    addNew: PropTypes.func,
    reloadItems: PropTypes.bool,
    items: PropTypes.array, // eslint-disable-line
    languages: PropTypes.array, // eslint-disable-line
    countries: PropTypes.array, // eslint-disable-line
    emptyStateText: PropTypes.string,
    isLoading: PropTypes.bool,
    fetchItemDetails: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired
  };

  static defaultProps = {
    addNew: () => {},
    isLoading: true,
    emptyStateText: '',
    reloadItems: false,
    items: [],
    countries: [],
    languages: []
  };

  state = {
    selectedItemKey: undefined
  };

  componentDidUpdate(prevProps) {
    if (prevProps.reloadItems !== this.props.reloadItems && this.props.reloadItems === true) {
      this.setSelected();
    }
  }

  setSelected = key => {
    if (key !== this.state.selectedItemKey) {
      this.setState(
        {
          selectedItemKey: key
        },
        () => {
          this.props.fetchItemDetails(this.state.selectedItemKey);
        }
      );
    }
  };

  render() {
    const { selectedItemKey } = this.state;
    const {
      items,
      isLoading,
      buttonText,
      emptyStateText,
      reloadItems,
      countries,
      languages
    } = this.props;
    return (
      <Box>
        {!isLoading && !reloadItems ? (
          <Box width={1} mb={5}>
            <Card pt={4}>
              <ItemsList
                list={items}
                selectedItemKey={selectedItemKey}
                setSelected={this.setSelected}
                emptyStateText={emptyStateText}
                countries={countries}
                languages={languages}
              />
              <Separator />
              <Flex width={1} justifyContent="center" alignItems="center">
                <AddButton
                  primary
                  width="100%"
                  color={COLORS.PRIMARY_BLUE}
                  onClick={value => {
                    this.props.addNew(value);
                    this.setState({
                      selectedItemKey: null
                    });
                  }}
                >
                  {buttonText}
                </AddButton>
              </Flex>
            </Card>
          </Box>
        ) : (
          <Box width={1} mb={5}>
            <Card py={4}>
              <ShimmerEffect>
                <NoAnimationFlex width={1} flexDirection="column">
                  <NoAnimationFlex px={4} py={2} flexDirection="column">
                    <Rect width={1} mb={4} />
                    <Rect width={1} mb={4} />
                    <Rect width={1} mb={4} />
                    <Rect width={1} mb={4} />
                    <Rect width={1} mb={4} />
                    <Rect width={1} mb={4} />
                    <Rect width={1} mb={4} />
                    <Rect width={1} mb={4} />
                    <Rect width={1} />
                  </NoAnimationFlex>
                </NoAnimationFlex>
              </ShimmerEffect>
            </Card>
          </Box>
        )}
      </Box>
    );
  }
}

export default ItemsListContainer;
