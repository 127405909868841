const createOption = (label, value, img, key, disabled, groupBy, groupIcon, searchable) => ({
  fieldValue: label,
  value,
  searchable: searchable || [label],
  fieldImage: img,
  key,
  children: label,
  disabled,
  groupBy,
  groupIcon
});

export const getPaymentMethodsOptions = paymentMethods =>
  paymentMethods.map(paymentMethod =>
    createOption(paymentMethod.Name, paymentMethod.PaymentMethodKey)
  );

export const getLanguagesOptions = languages =>
  languages.map(language => createOption(language.Name, language.Id));

export const getCountriesOptions = countries => {
  if (countries && countries.length !== 0) {
    const countriesOptions = countries.map(country =>
      createOption(country.NameEnglish, country.CountryId, null, country.CountryId)
    );

    return countriesOptions;
  }
  return undefined;
};

export const getCountOptions = n => {
  const numbers = Array.from(new Array(n), (x, index) => index + 1);
  const countOptions = numbers.map(number => createOption(number, number));
  return countOptions;
};
